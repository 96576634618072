
import SliderImg from "../../images/slider01.png";
import SliderImg02 from "../../images/slider02.png";
import SliderImg03 from "../../images/slider03.png";
import SliderImg04 from "../../images/slider04.png";
import SliderImg05 from "../../images/slider05.png";
import SliderImg06 from "../../images/slider06.png";
import SliderImg07 from "../../images/slider07.png";
import SliderImg08 from "../../images/slider08.png";
import SliderImg09 from "../../images/slider09.png";
import SliderImg10 from "../../images/slider10.png";
import SliderImg11 from "../../images/slider11.png";
import SliderImg12 from "../../images/slider12.png";
import SliderImg13 from "../../images/slider13.png";
import SliderImg14 from "../../images/slider14.png";
import SliderImg15 from "../../images/slider15.png";
import SliderImg16 from "../../images/slider16.png";

export const SliderData = [
  {
    id: "34of",
    slideImg: SliderImg,
  },
  {
    id: "asf54",
    slideImg: SliderImg02,
  },
  {
    id: "asfdfd54",
    slideImg: SliderImg03,
  },

  {
    id: "asfdfse4",
    slideImg: SliderImg04,
  },
  {
    id: "asfeet54",
    slideImg: SliderImg05,
  },

  {
    id: "assdfwef54",
    slideImg: SliderImg06,
  },
  {
    id: "asetetf54",
    slideImg: SliderImg07,
  },
  {
    id: "aswetvf54",
    slideImg: SliderImg08,
  },
  {
    id: "asf54gsee",
    slideImg: SliderImg09,
  },
  {
    id: "asf54dgsee",
    slideImg: SliderImg10,
  },
  {
    id: "asfd54dgsee",
    slideImg: SliderImg11,
  },
  {
    id: "asf54dfggsee",
    slideImg: SliderImg12,
  },

  {
    id: "asf54dgdfsee",
    slideImg: SliderImg13,
  },
  {
    id: "asf54dgsgdee",
    slideImg: SliderImg14,
  },
  {
    id: "asf54sdfdgsee",
    slideImg: SliderImg15,
  },
  {
    id: "asf54ddssgsdgsee",
    slideImg: SliderImg16,
  },





  
];
