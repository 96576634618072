export const RoadmapData = [
  {
    id: "45334",
    title: "5%",
    descriptionTitle: "Whitelist (500 Metamtes NFT, 4 per member).",
    description: "Please find rules on discord and twitter to become WL.",
  },
  {
    id: "tfdsfd4",
    title: "10%",
    // descriptionTitle: "",
    description: "Giveaway on Twitter, Facebook, Discord & Slack",
  },
  {
    id: "46ty",
    title: "15%",
    descriptionTitle: "Meta mates Ape Lounge Promotions at Gyms, café,",
    description: " Restaurants of New Jersey and New York ( Free MMAL : Tshirts, Beanies, tatoos , stickers giveaway)",
  },
  {
    id: "h7fgh5",
    title: "15%",
    descriptionTitle: "We mint 10 Meta mates from special collections",
    description: "and airdrop them to one per twitter account who can achieve 50 retweets and one per discord member who becomes community spreaders (bring 100 members into our discord group)",
  },
  {
    id: "o86gfh7",
    title: "20%",
    descriptionTitle: "Presale1 GOLD COLLECTION",
    description: "(2000 Metamates NFT) Auction begins at NYC Rooftop both online and with inperson guests",
  },
  {
    id: "8rasdcf",
    title: "30%",
    descriptionTitle: " Presale2 GOLD & DIAMOND COLLECTIONS",
    description: "(3000 Metamates NFT) Auction begin on Manhattan Yacht both online and with inperson guests",
  },
  {
    id: "75dfgh",
    title: "40%",
    descriptionTitle: "Full sale Reveal Generative drop of  GOLD , DIAMOND ",
    description: "Metamates NFTs (Online only) ",
  },

  {
    id: "75eryhh",
    title: "45%",
    descriptionTitle: "Special Ape Swag",
    description: " (t shirt with your Metamates Ape after 2 months, Sunglasses, hats after 3 months). You're going to get them for free! ",
  },


  {
    id: "75hrhb",
    title: "50%",
    // descriptionTitle: "Full sale Reveal Generative drop of  GOLD , DIAMOND , BLACK GOLD:",
    description: " Metamates Staking Official Launch date will be announced.  ",
  },



  {
    id: "75hhkh",
    title: "55%",
    descriptionTitle: "Staking will Begin on the",
    description: "best staking platforms + Special limited Collection Meta mates Ape Collection sale. ",
  },



  {
    id: "7sdf5h",
    title: "55%",
    // descriptionTitle: "Full sale Reveal Generative drop of  GOLD , DIAMOND , BLACK GOLD:",
    description: "We airdrop 3 ETH to 10 random MMAL holders. ",
  },


  {
    id: "7sdfs5h",
    title: "55%",
    // descriptionTitle: "Full sale Reveal Generative drop of  GOLD , DIAMOND , BLACK GOLD:",
    description: "We create a community wallet and fill it with 5 ETH. ",
  },



  {
    id: "7df5h",
    title: "60%",
    descriptionTitle: " Launch Metaverse Metamates",
    description: "Ape Lounge First Look Game Banner.",
  },
  {
    id: "7dsf5h",
    title: "65%",
    descriptionTitle: " 10% Metamates Revenue to be donated to Ukraine",
    description: " war heroes and families through organized channels (ex: UNICEF, U.N Crisis relief, World Food Programme, Save the Children, Voices of Children etc)",
  },
  {
    id: "75sdfh",
    title: "70%",
    // descriptionTitle: " Launch Metaverse Metamates",
    description: "More Philanthropic Giveaways ran by the Community",
  },
  {
    id: "7ds5h",
    title: "75%",
    descriptionTitle: " Exclusive deals for NFT Partnerships & brands partnering",
    description: "partnering (Lounges: Restaurants, Clubs, Lounges, Airport, Rooftops, Travel sites, hotels, Discount Car & Air Educational Organizations through travel Vendors) for MMAL holder & community. ",
  },
  {
    id: "75ddh",
    title: "60%",
    descriptionTitle: " Buy lands in the metaverse",
    description: "such as Sandbox, Decentraland, Cryptovoxels, Worldwde Webb Lands.",
  },
  {
    id: "7sdfd5h",
    title: "85%",
    // descriptionTitle: " Launch Metaverse Metamates",
    description: " Launch MetaApe  Coin for MMAL Holders and public sale through Crypto Exchange.",
  },

  {
    id: "sdf",
    title: "90%",
    descriptionTitle: " Partnership with Major Vendors of Apparels",
    description: "/ Food for exclusive discounts of MMAL holders & community.",
  },


  {
    id: "sdfds",
    title: "95%",
    descriptionTitle: " We will start the development of the Metamates",
    description: "ecosystem on metaverse and collaborate with major game companies and Mega metaverse land owners.",
  },

  {
    id: "454g",
    title: "100%",
    descriptionTitle: "  Exclusive Real-life parties with celebs",
    description: "and metaverse festivals will be held for community partners and MMAL holders.",
  },
];
