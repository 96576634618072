import Bannerimg from "../../images/slider01.png";
import Bannerimg2 from "../../images/slider03.png";
import Bannerimg3 from "../../images/slider04.png";


export const SliderData = [
  {
    id: "34of",
    slideImg: Bannerimg,
    title: "Metamates Ape Lounge",
    description:
      "MMAL Intro to NFT article has been published on leading media channels like WSJ, TechCrunch, Medium, Coin telegraph, Bitcoin.Com, Financial Times Cryptocurrencies, Decrypt, FOX, News Today and 450+ Tabloid Magazines.",
  },
  {
    id: "asf54",
    slideImg: Bannerimg2,
    title: " Metamates Ape Lounge",
   description:
    "MMAL has collaborated with North America’s Largest Fitness and wellness center to promote their NFT Social Platform and educate many NFT enthusiasts.",
  },
  {
    id: "h234g",
    slideImg: Bannerimg3,
    title: "Metamates Ape Lounge",
   
    description:
      " MMAL NFT has built the World’s #first of its kind of smart contracts to enable its holders to mint NFT at optimal gas price.",
  },
  // {
  //   id: "age353",
  //   slideImg: Bannerimg,
  //   title: "Metamates Ape Lounge",
  //   subtitle:
  //   "1.MMAL Intro to NFT article has been published on leading media channels like Forbes, WSJ, Techcrunch, Medium, Cointelegraph, Bitcoin.Com, Financial Times Cryptocurrencies, Decrypt.",
  // description:
  //   "2.MMAL has collaborated with North America’s Largest Fitness and wellness center to promote their NFT Social Platform and educate many NFT enthusiasts.",
  // },
  // {
  //   id: "et244",
  //   slideImg: Bannerimg,
  //   title: "Metamates Ape Lounge",
  //   subtitle:
  //   "1.MMAL Intro to NFT article has been published on leading media channels like Forbes, WSJ, Techcrunch, Medium, Cointelegraph, Bitcoin.Com, Financial Times Cryptocurrencies, Decrypt.",
  // description:
  //   "2.MMAL has collaborated with North America’s Largest Fitness and wellness center to promote their NFT Social Platform and educate many NFT enthusiasts.",
  // },
];
